import './assets/styles/App.scss';

import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { MemoryRouter as Router, Route, Switch } from 'react-router-dom';

import reducer from './reducers';
import Home from './pages/Home';
import Game from './pages/Game';
import GameEnd from './pages/GameEnd';
import GameSettigns from './pages/GameSettings';
import * as serviceWorker from './serviceWorker';

import PlayerData from './helpers/PlayerData';


const store = createStore(
    reducer, 
    composeWithDevTools( applyMiddleware(thunk) ) 
);

// Init game settings for debug
store.dispatch({ type: 'ADD_PLAYER', player: new PlayerData('Player 1') });
store.dispatch({ type: 'ADD_PLAYER', player: new PlayerData('Player 2') });

ReactDOM.render(
    <Provider store={store}>
        <Router
            initialEntries={["/", "/settings", "/game", "/game-end"]}
            initialIndex={0}>
            <Switch>           
                <Route exact path="/game" component={Game}></Route>
                <Route exact path="/game-end" component={GameEnd}></Route>
                <Route exact path="/settings" component={GameSettigns}></Route>
                <Route component={Home}></Route>
            </Switch>
        </Router>
    </Provider>, 
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
