import React, { Component } from 'react';

class Card extends Component {
    // constructor(props) {
    //     super(props);

    //     this.state = {
    //         prevName: '',
    //     };
    // }

    // componentDidMount() {
    //     console.log('Card Mounted');
    //     // this.setState({
    //     //     animClass: 'showAnim'
    //     // });
    //     // this.timerID = setInterval(
    //     //   () => this.tick(),
    //     //   1000
    //     // );
    // }

    // componentWillUnmount() {
    //     console.log('Before unmount');
    // }   

    markers() {
        let ar = [];

        for (let i = 0; i < this.props.workers; i++) {
            ar.push(<div key={i} className="marker"></div>);
        }

        return ar;
    }

    handleClick(e){
        e.stopPropagation();

        if(this.props.onClick && this.props.name)
            this.props.onClick(this.props.name);
    }

    render() {
        let className = 'card';

        if(this.props.name){
            className += ' ' + this.props.name;
        }
            
        if(this.props.extraClass){
            className += ' ' + this.props.extraClass;
        } 

        return (
            <div 
                className={className}
                onClick={this.handleClick.bind(this)} >
                {this.markers()}
            </div>
        );
    }
}

export default Card;