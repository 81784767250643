export default class PlayerData{
    constructor(nickname = ''){
        this.nickname = nickname ? nickname : 'Player_' + (Math.random() * 10000);
        this.points = 0;
        this.workers = 5;
        this.infirmary = []; // list for workers in infirmary
        this.kingdom = [];
        this.totalPersons = 0;
        this.stats = {
            kingdom: 0,

            infirmary: 0,
            diversity: 0,
            most: Array.apply(null, Array(8)).map(function (x, i) { return 0; }),
            mostTotal: 0
        }; 
        this.color = '';
        this.isBot = false;

        for (let i = 0; i < 8; i++) {
            this.kingdom.push( 0 );
        }
    }
}