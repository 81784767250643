import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Players from '../layouts/Players';
import { Redirect } from 'react-router-dom';

class GameSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            kingdomType: 1,
            marketFill: 1,
            startGame: false
        };
        
        this.startGame = this.startGame.bind(this);
        this.onChangeKingdomType = this.onChangeKingdomType.bind(this);
        this.onChangeMarketFill = this.onChangeMarketFill.bind(this);
    }

    startGame(){
        this.props.onStartGame({
            kingdomType: this.state.kingdomType,
            marketFill: this.state.marketFill,
            playersLen: this.props.players.length
        });

        this.setState({startGame: true});
    }

    onChangeKingdomType(obj){
        this.setState({
            kingdomType: obj.value
        });
    }

    onChangeMarketFill(obj){
        this.setState({
            marketFill: obj.value
        });
    }

    render() {
        if(this.state.startGame === true)
            return <Redirect to="/game" />;

        const kingdomTypeOpts = [
            { value: 1, label: 'A side' },
            { value: 2, label: 'B side' },
            { value: 3, label: 'Random shuffle' },
        ];

        const marketFillOpts = [
            { value: 1, label: 'Standard' },
            { value: 2, label: 'Random' },
        ];

        return (
            <div className="gameSettings">
                <h1>Game Settings</h1>
                <Players />
                <div className="kingdomType block">
                    <label>
                        <span>Select kingdom type:</span>
                        <Select 
                            className="select" 
                            options={kingdomTypeOpts} 
                            defaultValue={kingdomTypeOpts[0]} 
                            isSearchable={false}
                            onChange={this.onChangeKingdomType}
                        />
                    </label>
                    <div className="sign">
                        <p>A - standard side with balanced rules.</p>
                        <p>B - changed buildings properties, points rewards. Board game rules recommend play on A side at least 5 times before try B side</p>
                        <p>Random shuffle - both A and B sides will participate and will be choosen randomly for each building</p>
                    </div>
                </div>

                <div className="marketFill block">
                    <label>
                        <span>Select market fill type:</span>
                        <Select 
                            className="select" 
                            options={marketFillOpts} 
                            defaultValue={marketFillOpts[0]} 
                            isSearchable={false}
                            onChange={this.onChangeMarketFill}
                        />
                    </label>
                    <div className="sign">
                        <p>Standard - Workers cards shuffled, limited by players count and sorted by card level</p>
                        <p>Random - cards shuffled with random order</p>
                    </div>
                </div>

                <div className="playBtn" onClick={this.startGame}>Lets Play</div>
            </div>
        );
    }
}

export default connect(
    state => ({
        players: state.players.list,
        gameInfo: state.players.gameInfo
    }),
    dispatch => ({
        onStartGame: opts => {
            dispatch({ type: 'SETUP_BASE_KINGDOM', kingdomType: opts.kingdomType });

            // Fill STANDART or RANDOM and add playersCount - 2, 3, 4
            dispatch({ 
                type: opts.marketFill === 1 ? 'STANDART_FILL_MARKET' : 'RANDOM_FILL_MARKET', 
                playersCount: opts.playersLen 
            }); 

            dispatch({ type: 'START_GAME' });
        }
    })
)(GameSettings);
