import React, { Component } from 'react';
import { connect } from 'react-redux';
import Market from '../game/Market';
import Kingdom from '../game/Kingdom';
import GameHeader from '../game/GameHeader';
import { Redirect } from 'react-router-dom';

class Game extends Component {
    constructor(props) {
        super(props);
        this.getKingdoms = this.getKingdoms.bind(this);
        this.checkBotTurn = this.checkBotTurn.bind(this);
    }

    getKingdoms() {
        const players = this.props.players;
        let ar = [];

        for (let i = 0; i < players.length; i++) {
            ar.push(<Kingdom key={i} index={i} playerIndex={i} />);
        }

        return ar;
    }

    componentDidMount() {
        this.checkBotTurn();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.gameInfo.activePlayerIndex !== this.props.gameInfo.activePlayerIndex) {
            this.checkBotTurn();
        }
    }

    checkBotTurn() {
        const playerIndex = this.props.gameInfo.activePlayerIndex;
        const player = this.props.players[playerIndex];

        // Calc bot turn
        if (player.isBot === true) {
            console.log('Bot turn', player.nickname);

            const BUILDINGS = this.props.buildings;
            let slots = [];
            let weightList = [];

            //#region Prepare weight array

            // Clone kingdomTemplate array
            for (let i = 0; i < this.props.kingdomTemplate.length; i++) {
                const place = this.props.kingdomTemplate[i];
                weightList.push(Object.assign({}, place));
            }

            // Setup weight balance
            for (let i = 0; i < weightList.length; i++) {
                const place = weightList[i];

                // Add weight for each worker on place
                place.weight += player.kingdom[i];

                // Add double points to Cottage for every injuried worker
                if (place.building === 'cottage') {
                    place.weight += player.kingdom[player.kingdom.length - 1] * 2;
                }

                // Add weight for building points calc
                switch (place.building) {
                    case 'mill':
                        if (place.type === 2) {
                            place.weight += player.kingdom[BUILDINGS.indexOf('cottage')];
                        }
                        break;
                    case 'brewery':
                        place.weight += player.kingdom[BUILDINGS.indexOf('mill')];

                        // Add weight for pairs
                        if (place.type === 2) {
                            let innWorkers = player.kingdom[BUILDINGS.indexOf('inn')];
                            let castleWorkers = player.kingdom[BUILDINGS.indexOf('castle')];

                            if (innWorkers > 0 && castleWorkers > 0) {
                                let diff = Math.abs(innWorkers - castleWorkers);
                                let pairs = 1;

                                if (innWorkers > castleWorkers) {
                                    pairs = innWorkers - diff;
                                } else {
                                    pairs = castleWorkers - diff;
                                }

                                // Add doubled extra points for each pair
                                place.weight += pairs * 2;
                            }
                        }
                        break;
                    case 'cottage':
                        if (place.type === 1) {
                            place.weight += player.kingdom[BUILDINGS.indexOf('mill')];
                            place.weight += player.kingdom[BUILDINGS.indexOf('brewery')];
                        }
                        break;
                    case 'guardhouse':
                        if (place.type === 1) {
                            place.weight += player.kingdom[BUILDINGS.indexOf('barracks')];
                            place.weight += player.kingdom[BUILDINGS.indexOf('inn')];
                        } else {
                            place.weight += player.kingdom[BUILDINGS.indexOf('cottage')];
                            place.weight += player.kingdom[BUILDINGS.indexOf('brewery')];
                            place.weight += player.kingdom[BUILDINGS.indexOf('inn')];
                        }
                        break;
                    case 'barracks':
                        if (place.type === 2) {
                            place.weight += player.kingdom[BUILDINGS.indexOf('castle')];
                            place.weight += player.kingdom[BUILDINGS.indexOf('inn')];
                        }
                        break;
                    case 'inn':
                        if (place.type === 1) {
                            place.weight += player.kingdom[BUILDINGS.indexOf('brewery')];
                        }
                        break;
                    case 'castle':
                        if (place.type === 2) {
                            place.weight += player.kingdom[BUILDINGS.indexOf('infirmary')];
                        }
                        break;
                    default: break;
                }
            }

            weightList.sort( (a, b) => {
                if (a.weight < b.weight) return 1;
                if (a.weight === b.weight) return 0;
                if (a.weight > b.weight) return -1;
                return 0;
            });
            // console.log('sorted weightList', weightList);
            //#endregion

            let getSlotIndexByName = cardName => {
                return slots.findIndex(slot => {
                    const splitedName = slot.name.split('-');

                    if (
                        slot.name === cardName ||
                        splitedName[0] === cardName ||
                        (splitedName.length > 1 && splitedName[1] === cardName)
                    ) {
                        return true;
                    }

                    return false;
                });
            };

            //#region Buy selected slot
            let buySlot = (slotIndex, cardName) => {
                const slot = slots[slotIndex];
                let workersMod = 0;

                // setTimeout(() => {
                    // Update player workers count
                    workersMod -= slotIndex; // slot price in workers

                    if (slot.workers && slot.workers > 0)
                        workersMod += slot.workers;

                    if (workersMod !== 0)
                        this.props.onPlayerWorkersUpdate(playerIndex, workersMod);

                    this.props.onBuyWorker({
                        slotIndex: slotIndex,
                        playerIndex: playerIndex,
                        placeName: cardName,
                        // rangeModalWorkers: this.state.rangeModalWorkers
                    });
                // }, 1000);
            };
            //#endregion

            //#region Select random slot func
            // let playRandom = () => {
            //     if (slots.length === 0) {
            //         console.error('Slots len = 0!');
            //         return;
            //     }

            //     const slotIndex = slots.length === 1 ? 0 : Utils.getRandomInt(0, slots.length - 1);
            //     const slot = slots[slotIndex];
            //     let cardName = slot.name;
            //     const splitedName = cardName.split('-');

            //     if (splitedName.length > 1) {
            //         cardName = splitedName[Utils.getRandomInt(0, 1)];
            //     }

            //     buySlot(slotIndex, cardName);
            // };
            //#endregion

            //#region Prepare slots
            for (let i = 0; i < this.props.market.slots.length; i++) {
                const slot = this.props.market.slots[i];

                if (i <= player.workers) {
                    slots.push(slot);
                } else {
                    break;
                }
            }

            if (typeof slots === 'undefined' || slots.length === 0) {
                console.error('Something wrong with slots!');
                return;
            }
            //#endregion

            //#region Select card for buy
            for (let i = 0; i < weightList.length; i++) {
                const place = weightList[i];
                const slotIndex = getSlotIndexByName(place.building);

                if(slotIndex >= 0){
                    console.log(player.nickname + ' select slot ' + slotIndex + ' with card ' + place.building);
                    buySlot(slotIndex, place.building);
                    break;
                }
            }
            //#endregion
        }
    }

    render() {
        if (this.props.gameInfo.isGameEnd === true) {
            return <Redirect to="/game-end" />;
        }

        return (
            <>
                <GameHeader />
                <Market />
                <div className="kingdoms" data-players={this.props.players.length}>
                    {this.getKingdoms()}
                </div>
            </>
        );
    }
}

export default connect(
    state => ({        
        market: state.market,
        players: state.players.list,
        gameInfo: state.players.gameInfo,
        buildings: state.players.buildings,
        kingdomTemplate: state.players.kingdomTemplate
    }),
    dispatch => ({
        onPlayerWorkersUpdate: (playerIndex, mod) => {
            dispatch({
                type: 'UPDATE_PLAYER_WORKERS',
                playerIndex: playerIndex,
                mod: mod
            });
        },
        onBuyWorker: opts => {
            opts.type = 'WORKER_CHOSEN';
            dispatch(opts);
        }
    })
)(Game);
