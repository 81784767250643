import React, { Component } from 'react';
import { connect } from 'react-redux';

class Player extends Component {
    constructor(props) {
        super(props);
        this.toggleBot = this.toggleBot.bind(this);
    }

    toggleBot(){
        this.props.onToggleBot(!this.props.isBot, this.props.index);
    }

    render() {
        let className = 'player';

        if(this.props.isBot === true)
            className += ' bot';

        return (
            <li className={className}>
                <div className="avatar" onClick={this.toggleBot}></div>
                <div className="nickname">{this.props.nickname}</div>
            </li>
        );
    }
}

export default connect(
    state => ({}),
    dispatch => ({
        onToggleBot: (isBot, playerIndex) => {
            dispatch({ type: 'TOGGLE_BOT', isBot, playerIndex });
        }
    })
)(Player);
