import React, { Component } from 'react';
import Card from './Card';

class ChooseWorkerModal extends Component {
    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(cardName){
        this.props.onCardSelect(cardName, this.props.slotIndex);
    }

    render() {
        return (
            <div className="modal">
                <div
                    className="modal-content"
                    onClick={e => e.stopPropagation()} >
                    <div className="title">Choose a card</div>
                    <div className="cards">
                        <Card name={this.props.card_1} onClick={this.handleSelect} />
                        <Card name={this.props.card_2} onClick={this.handleSelect} />
                    </div>                    
                </div>
            </div>
        );
    }
}

export default ChooseWorkerModal;