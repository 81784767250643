import React, { Component } from 'react';
import { connect } from 'react-redux';
import Chart from "react-google-charts";
import { Redirect } from 'react-router-dom';
// import Utils from '../helpers/Utils';

class GameEnd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            playAgain: false,
        };

        this.handlePlayAgain = this.handlePlayAgain.bind(this);
    }

    handlePlayAgain() {
        this.setState({ playAgain: true });
    }

    getWinners(players, maxPoints){
        let ar = [];
        for (let i = 0; i < players.length; i++) {
            const player = players[i];
            let className = '';

            if(player.points === maxPoints)
                className += 'winner';

            ar.push(<li className={className}>
                <div className="nickname">{player.nickname}</div>
                <div className="points"> {player.points}</div>
            </li>);
        }
        return ar;
    }

    render() {
        if (this.state.playAgain === true)
            return <Redirect to="/settings" />;

        const players = this.props.players;
        let totalPointsChartData = [["Player", "Points", { role: "style" }]];
        let pointsInfoChartData = [[
            "Player",
            "Earned points", "Infirmary", "Diversity",
            "Mill majority", "Brewery majority", "Cottage majority", "Guardhouse majority",
            "Barracks majority", "Inn majority", "Castle majority", "Infirmary majority",
        ]];
        let points = [];

        //#region Fill charts data
        for (let i = 0; i < players.length; i++) {
            const player = players[i];

            totalPointsChartData.push([
                player.nickname,
                player.points,
                player.color
            ]);

            pointsInfoChartData.push([
                player.nickname,
                player.stats.kingdom,
                player.stats.infirmary,
                player.stats.diversity,
                ...player.stats.most
            ]);

            points.push(player.points);
        }
        //#endregion

        //#region Fill random data for debug
        // for (let i = 0; i < players.length; i++) {
        //     const player = players[i];

        //     totalPointsChartData.push([
        //         player.nickname,
        //         Utils.getRandomInt(50, 150),
        //         player.color
        //     ]);

        //     let most = [Utils.getRandomInt(0, 50),Utils.getRandomInt(0, 50),Utils.getRandomInt(0, 50),Utils.getRandomInt(0, 50),Utils.getRandomInt(0, 50),Utils.getRandomInt(0, 50),Utils.getRandomInt(0, 50),Utils.getRandomInt(0, 50)];

        //     pointsInfoChartData.push([
        //         player.nickname,
        //         Utils.getRandomInt(10, 50),
        //         Utils.getRandomInt(0, 20) * -1,
        //         Utils.getRandomInt(0, 50),
        //         ...most
        //     ]);
        // }
        //#endregion

        return (
            <div className="gameEnd">
                <ul className="winners">
                    {this.getWinners(players, Math.max(...points))}
                </ul>
                <div className="chart">
                    <Chart
                        width={"500px"}
                        height={"450px"}
                        chartType="ColumnChart"
                        loader={<div>Loading Chart</div>}
                        data={totalPointsChartData}
                        options={{
                            legend: 'none',
                            annotations: {
                                textStyle: {
                                    color: "#ffffff"
                                }
                            },
                            hAxis: {
                                textStyle: {
                                    color: "#ffffff"
                                },
                                titleTextStyle: {
                                    color: "#ffffff"
                                },
                            },
                            vAxis: {
                                title: "Points",
                                minValue: 0,
                                textStyle: {
                                    color: "#ffffff"
                                },
                                titleTextStyle: {
                                    color: "#ffffff"
                                },
                            },
                            animation: {
                                startup: true,
                                easing: 'linear',
                                duration: 250,
                            },
                            backgroundColor: { fill: 'transparent' }
                        }}
                    />
                </div>

                <div className="chart">
                    <Chart
                        width={"900px"}
                        height={"450px"}
                        chartType="ColumnChart"
                        loader={<div>Loading Chart</div>}
                        data={pointsInfoChartData}
                        options={{
                            title: "Players points chart",
                            titleTextStyle: {
                                color: "#ffffff"
                            },
                            legend: {
                                textStyle: {
                                    color: "#ffffff"
                                },
                            },
                            chartArea: { width: "50%" },
                            bar: { groupWidth: '75%' },
                            isStacked: true,
                            hAxis: {
                                title: "Players",
                                textStyle: {
                                    color: "#ffffff"
                                },
                                titleTextStyle: {
                                    color: "#ffffff"
                                },
                            },
                            vAxis: {
                                title: "Points",
                                minValue: 0,
                                textStyle: {
                                    color: "#ffffff"
                                },
                                titleTextStyle: {
                                    color: "#ffffff"
                                },
                            },
                            colors: [
                                "#087e8b", "#c81d25", "#375796",
                                "#ffcab1", "#edc4b1", "#d6baad", "#bf9683",
                                "#968b85", "#6e7058", "#83846f", "#bcbf8f"
                            ],
                            animation: {
                                startup: true,
                                easing: 'linear',
                                duration: 250,
                            },
                            backgroundColor: { fill: 'transparent' }
                        }}
                    />
                </div>

                <div className="playAgainBtn" onClick={this.handlePlayAgain}>Play again?</div>
            </div>
        );
    }
}

export default connect(
    state => ({
        players: state.players.list
    }),
    dispatch => ({})
)(GameEnd);