import Utils from "../helpers/Utils";

const BUILDINGS = ['mill', 'brewery', 'cottage', 'guardhouse', 'barracks', 'inn', 'castle', 'infirmary'];
const GAME_END_MAX_CARDS = 12;
const initialState = {
    buildings: [...BUILDINGS],
    gameInfo: {
        turn: 0,
        activePlayerIndex: -1,
        isGameEnd: false
    },
    list: [],
    kingdomTemplate: [],
};

/**
 * Get info about place in kingdom
 * @param {Array} templateList - kingdom template array
 * @param {string} placeName - building name for search
 */
function getPlaceByName(templateList, placeName) {
    if (
        typeof templateList === 'undefined' ||
        typeof placeName === 'undefined'
    ) {
        console.error('templateList or placeName is undefined !!!');
        return null;
    }

    for (let i = 0; i < templateList.length; i++) {
        const place = templateList[i];

        if (place.building === placeName) {
            // let res = Object.assign({}, place);
            // res.workers = playerWorkers[i];
            return place;
        }
    }

    console.error('Place - ' + placeName + ' not founded in list', templateList);
    return null;
}

/**
 * Change workers count
 * @param {Player} player - player object for update
 * @param {int} mod - workers modifier
 */
function UpdateWorkers(player, mod) {
    mod = parseInt(mod);
    player.workers += mod;

    if (player.workers > 5) {
        player.points += player.workers - 5;
        player.workers = 5;
    }

    if (player.workers < 0) {
        console.error('Something wrong with player.workers calc!');
        player.workers = 0;
    }
}

/**
 * Add points for all players who have workers in selected place
 * @param {string} placeName
 * @param {Array} players 
 * @param {int} points 
 */
function addPointsForAll(placeName, players, points) {
    const placeIndex = BUILDINGS.indexOf(placeName);
    players.forEach(player => {
        player.points += player.kingdom[placeIndex] * points;
    });
}

/**
 * Make attack on other players
 * @param {Array} players - array of players
 * @param {int} attackerIndex - index of player that make attack
 * @param {int} knights - count of knights
 */
function attackAction(players, attackerIndex, knights) {
    const guardhouseIndex = BUILDINGS.indexOf('guardhouse');

    for (let i = 0; i < players.length; i++) {
        const player = players[i];

        // Dont make self attack or block action by other player guards
        if (i === attackerIndex || player.kingdom[guardhouseIndex] >= knights)
            continue;

        // Move worker to infirmary if have some
        for (let j = 0; j < player.kingdom.length; j++) {
            const workersCount = player.kingdom[j];

            if (workersCount > 0) {
                player.kingdom[j] -= 1;
                player.kingdom[player.kingdom.length - 1] += 1;
                player.infirmary.push(BUILDINGS[j]);
                break;
            }
        }
    }
}

/**
 * Will summ workers from selected places and apply points
 * @param {Object} player - Player object
 * @param {Array} places - array of places for calc
 * @param {int} points - player points += workers * points
 */
function addPlacesSumPoints(player, places, points) {
    let workers = 0;

    places.forEach(place => {
        workers += player.kingdom[BUILDINGS.indexOf(place)];
    });
    player.points += workers * points;
}

function healAction(player) {
    if (player.kingdom[player.kingdom.length - 1] > 0) {
        const healedWorker = player.infirmary.pop();

        player.kingdom[player.kingdom.length - 1] -= 1;
        player.kingdom[BUILDINGS.indexOf(healedWorker)] += 1;
    }
}


export default function gameStore(state = initialState, action) {
    if (action.type === 'SETUP_BASE_KINGDOM') {
        /**
         * Kingdom types
         * 1 = A 
         * 2 = B
         * 3 = random
         */
        let kingdomType = action.kingdomType ? action.kingdomType : 1;
        let template = [];

        for (let i = 0; i < BUILDINGS.length; i++) {
            const building = BUILDINGS[i];
            let _type = kingdomType;
            let weight = 0;

            if (_type === 3) {
                _type = Utils.getRandomInt(1, 2);
            }

            //#region Setup place weight for AI calc
            switch (building) {
                case 'mill': weight = _type === 1 ? Utils.getRandomInt(0, 4) : Utils.getRandomInt(2, 6);  break;
                case 'brewery': weight = _type === 1 ? Utils.getRandomInt(2, 6) : Utils.getRandomInt(0, 2); break;
                case 'cottage': weight = _type === 1 ? Utils.getRandomInt(0, 4) : Utils.getRandomInt(0, 6); break;
                case 'guardhouse': weight = _type === 1 ? Utils.getRandomInt(2, 6) : Utils.getRandomInt(6, 10); break;
                case 'barracks': weight = _type === 1 ? Utils.getRandomInt(6, 10) : Utils.getRandomInt(8, 10); break;
                case 'inn': weight = _type === 1 ? Utils.getRandomInt(6, 10) : Utils.getRandomInt(4, 8); break;
                case 'castle': weight = _type === 1 ? Utils.getRandomInt(4, 10) : Utils.getRandomInt(4, 8); break;
                default: break;
            }
            //#endregion

            template.push({
                index: i,
                building,
                type: _type,
                workers: 0,
                weight // need for AI calc
            });
        }

        return {
            ...state,
            kingdomTemplate: template
        };
    }
    else if (action.type === 'ADD_PLAYER' && action.player) {
        const PLAYER_COLOR = ["#3f88c5", "#d72638", "#8ea604", "#ec9f05"];
        let players = [...state.list];

        action.player.color = PLAYER_COLOR[players.length];
        players.push(action.player);

        return {
            ...state,
            list: players
        };
    }
    else if (action.type === 'REMOVE_PLAYER') {
        let players = [...state.list];

        players.pop();

        return {
            ...state,
            list: players
        };
    }
    else if (
        action.type === 'TOGGLE_BOT' &&
        typeof action.isBot !== 'undefined' &&
        typeof action.playerIndex !== 'undefined'
    ) {
        return {
            ...state,
            list: state.list.map((player, ind) => {
                if (ind === action.playerIndex) {
                    player.isBot = action.isBot;
                }

                return player;
            })
        };
    }
    else if (action.type === 'START_GAME') {
        let gameInfo = Object.assign({}, state.gameInfo);

        gameInfo.turn = 1;
        gameInfo.activePlayerIndex = 0;
        gameInfo.isGameEnd = false;

        return {
            ...state,
            gameInfo: gameInfo,
            list: state.list.map(player => {
                // Reset player params
                player.points = 0;
                player.workers = 5;
                player.infirmary = []; 
                player.kingdom = Array.apply(null, Array(8)).map(function (x, i) { return 0; });
                player.totalPersons = 0;
                player.stats = {
                    kingdom: 0,
                    infirmary: 0,
                    diversity: 0,
                    most: Array.apply(null, Array(8)).map(function (x, i) { return 0; }),
                    mostTotal: 0
                }; 

                return player;
            })
        };
    }
    else if (action.type === 'UPDATE_PLAYER_WORKERS' && typeof action.mod !== 'undefined') {
        return {
            ...state,
            list: state.list.map((player, ind) => {
                if (ind === action.playerIndex) {
                    UpdateWorkers(player, action.mod);
                }

                return player;
            })
        };
    }
    else if (
        action.type === 'WORKER_CHOSEN' &&
        typeof action.playerIndex !== 'undefined' &&
        typeof action.placeName !== 'undefined' &&
        typeof action.slotIndex !== 'undefined' && action.slotIndex >= 0 && action.slotIndex < 6
    ) {
        let players = [...state.list];
        let activePlayer = players[action.playerIndex];
        let place = getPlaceByName(state.kingdomTemplate, action.placeName);
        let workers;
        let gameInfo = Object.assign({}, state.gameInfo);
        let page = state.page;

        // Increase workers counter for active player
        activePlayer.kingdom[place.index] += 1; // increase workers count on selected place
        activePlayer.totalPersons += 1;

        // Calc points based on place
        workers = parseInt(activePlayer.kingdom[place.index]);
        if (place.type === 1) {
            switch (place.building) {
                case 'mill': activePlayer.points += workers * 2; break;
                case 'brewery':
                    activePlayer.points += workers * 2;
                    UpdateWorkers(activePlayer, workers);
                    addPointsForAll('mill', players, 2);
                    break;
                case 'cottage':
                    // if have injuried workers - heal one
                    healAction(activePlayer);
                    addPlacesSumPoints(activePlayer, ['mill', 'brewery', 'cottage'], 2);
                    break;
                case 'guardhouse':
                    addPlacesSumPoints(activePlayer, ['guardhouse', 'barracks', 'inn'], 2);
                    break;
                case 'barracks':
                    attackAction(players, action.playerIndex, workers);
                    activePlayer.points += workers * 3;
                    break;
                case 'inn':
                    activePlayer.points += workers * 4;
                    addPointsForAll('brewery', players, 3);
                    break;
                case 'castle':
                    activePlayer.points += workers * 5;
                    UpdateWorkers(activePlayer, workers);
                    break;
                default: break;
            }
        } else if (place.type === 2) {
            switch (place.building) {
                case 'mill':
                    activePlayer.points += workers * 2;
                    addPointsForAll('cottage', players, 3);
                    break;
                case 'brewery':
                    let innWorkers = activePlayer.kingdom[BUILDINGS.indexOf('inn')];
                    let castleWorkers = activePlayer.kingdom[BUILDINGS.indexOf('castle')];

                    UpdateWorkers(activePlayer,
                        activePlayer.kingdom[BUILDINGS.indexOf('mill')] +
                        activePlayer.kingdom[BUILDINGS.indexOf('brewery')]
                    );

                    if (innWorkers > 0 && castleWorkers > 0) {
                        let diff = Math.abs(innWorkers - castleWorkers);
                        let pairs = 1;

                        if (innWorkers > castleWorkers) {
                            pairs = innWorkers - diff;
                        } else {
                            pairs = castleWorkers - diff;
                        }

                        activePlayer.points += pairs * 10;
                    }
                    break;
                case 'cottage':
                    // if have injuried workers - heal one
                    healAction(activePlayer);
                    activePlayer.points += workers * 2;
                    break;
                case 'guardhouse':
                    addPlacesSumPoints(activePlayer, ['guardhouse', 'cottage', 'brewery'], 2);
                    addPointsForAll('inn', players, 3);
                    break;
                case 'barracks':
                    attackAction(players, action.playerIndex, workers);
                    addPlacesSumPoints(activePlayer, ['barracks', 'inn', 'castle'], 3);
                    break;
                case 'inn':
                    let maxWorkers = Math.max(...activePlayer.kingdom.slice(0, -1));
                    activePlayer.points += workers * maxWorkers * 2;
                    break;
                case 'castle':
                    if (
                        typeof action.rangeModalWorkers !== 'undefined' &&
                        action.rangeModalWorkers >= 0 &&
                        action.rangeModalWorkers !== activePlayer.workers
                    ) {
                        // if have diff workers count - player sell or buy workers
                        let diff = Math.abs(action.rangeModalWorkers - activePlayer.workers);


                        if (action.rangeModalWorkers < activePlayer.workers) {
                            // add points
                            activePlayer.points += diff;
                        } else if (action.rangeModalWorkers > activePlayer.workers) {
                            // remove points
                            activePlayer.points -= diff;
                        }

                        // set new workers count
                        activePlayer.workers = parseInt(action.rangeModalWorkers);
                    }

                    addPlacesSumPoints(activePlayer, ['castle', 'infirmary'], 4);
                    break;
                default: break;
            }
        }

        // Check game end
        let fullKingdoms = 0;
        for (let i = 0; i < players.length; i++) {
            const player = players[i];

            if (player.totalPersons < GAME_END_MAX_CARDS)
                break;

            fullKingdoms++;
        }

        if (fullKingdoms === players.length) {
            // if all kingdoms are full - calc final points 
            let infirmaryInd = BUILDINGS.indexOf('infirmary');
            let maxList = []; // save numbers of workers for calc most winners

            // prepare maxList by filling with empty arrays
            for (let i = 0; i < state.kingdomTemplate.length; i++) {
                maxList.push([]);
            }

            for (let i = 0; i < players.length; i++) {
                const player = players[i];
                let pointsMod = 0;

                player.stats.kingdom = player.points;

                //#region Minus points for infirmary
                if (state.kingdomTemplate[infirmaryInd].type === 1) {
                    pointsMod = player.kingdom[infirmaryInd];
                } else {
                    pointsMod = player.kingdom[infirmaryInd] * 2;
                }

                player.stats.infirmary = -pointsMod;
                player.points -= pointsMod;
                pointsMod = 0; // reset mod
                //#endregion 

                //#region Diversity and fill max array
                let buildingWithWorker = 0;
                for (let j = 0; j < player.kingdom.length; j++) {
                    const workersCount = player.kingdom[j];
                    if (workersCount > 0) {
                        buildingWithWorker += 1;
                        maxList[j].push(workersCount);
                    }
                }
                player.points += buildingWithWorker * buildingWithWorker;
                player.stats.diversity = buildingWithWorker * buildingWithWorker;
                //#endregion 
            }

            //#region Add points for most
            for (let i = 0; i < maxList.length; i++) {
                const maxAr = maxList[i];
                const placeType = state.kingdomTemplate[i].type;

                if (maxAr.length === 0)
                    continue;

                const winNumber = Math.max(...maxAr);

                for (let p = 0; p < players.length; p++) {
                    const player = players[p];

                    // if player building win in most
                    if (player.kingdom[i] === winNumber) {
                        let pointsMod = 0;

                        switch (BUILDINGS[i]) {
                            case 'mill': pointsMod = placeType === 1 ? 10 : 14; break;
                            case 'brewery': pointsMod = placeType === 1 ? 11 : 12; break;
                            case 'cottage': pointsMod = 12; break;
                            case 'guardhouse': pointsMod = placeType === 1 ? 13 : 8; break;
                            case 'barracks': pointsMod = placeType === 1 ? 14 : 8; break;
                            case 'inn': pointsMod = placeType === 1 ? 15 : 12; break;
                            case 'castle': pointsMod = 16; break;
                            case 'infirmary':
                                if (placeType === 2) {
                                    pointsMod -= 10;
                                }
                                break;
                            default: break;
                        }

                        player.points += pointsMod;
                        player.stats.most[i] = pointsMod;
                        player.stats.mostTotal += pointsMod;
                    }
                }
            }
            //#endregion

            // Toggle game end flag
            console.log('GAME END!!!');
            gameInfo.isGameEnd = true;
        } else {
            // Next player turn
            gameInfo.activePlayerIndex += 1;

            if (gameInfo.activePlayerIndex >= players.length) {
                gameInfo.turn += 1;
                gameInfo.activePlayerIndex = 0;
            }
        }

        return {
            ...state,
            list: players,
            gameInfo: gameInfo,
            page: page
        };
    }

    return state;
}