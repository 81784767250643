import React, { Component } from 'react';
import { connect } from 'react-redux';
import Player from './Player';
import PlayerData from '../helpers/PlayerData';

class Players extends Component {
    constructor(props) {
        super(props);

        this.getPlayers = this.getPlayers.bind(this);
        this.addPlayer = this.addPlayer.bind(this);
        this.getAddPlayerBtn = this.getAddPlayerBtn.bind(this);
        this.getRemovePlayerBtn = this.getRemovePlayerBtn.bind(this);
        this.removeLastPlayer = this.removeLastPlayer.bind(this);
    }

    getPlayers(){
        let ar = [];

        for (let i = 0; i < this.props.players.length; i++) {
            const player = this.props.players[i];
            ar.push( <Player key={i} index={i} nickname={player.nickname} isBot={player.isBot} /> );
        }

        return ar;
    }

    getAddPlayerBtn(){
        if(this.props.players.length < 4)
            return <li className="btn addBtn" onClick={this.addPlayer}></li>;
        return;
    }

    getRemovePlayerBtn(){
        if(this.props.players.length > 2)
            return <li className="btn removeBtn" onClick={this.removeLastPlayer}></li>;
        return;
    }

    addPlayer(){
        this.props.onAddPlayer('Player ' + (this.props.players.length + 1));
    }

    removeLastPlayer(){
        this.props.onRemovePlayer();
    }

    render() {
        return (
            <div className="players block">
                <ul className="list">
                    {this.getPlayers()}
                    {this.getAddPlayerBtn()}                    
                    {this.getRemovePlayerBtn()}                    
                </ul>
                <div className="sign">
                    <p>* Click on avatar for toggle Human-Bot control</p>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        players: state.players.list,
    }),
    dispatch => ({
        onAddPlayer: nickname => {
            dispatch({ type: 'ADD_PLAYER', player: new PlayerData(nickname) });
        },
        onRemovePlayer: () => {
            dispatch({ type: 'REMOVE_PLAYER' });
        }
    })
)(Players);
