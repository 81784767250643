import React, { Component } from 'react';
import { connect } from 'react-redux';

class Kingdom extends Component {

    constructor(props) {
        super(props);

        this.state = {
            collapsed: true,
        };

        this.getSlots = this.getSlots.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);
    }

    getSlots(){
        const player = this.props.players[this.props.playerIndex];
        let ar = [];

        for (let i = 0; i < 8; i++) {
            const workersCount = player.kingdom[i];
            const placeName = this.props.kingdomTemplate[i].building;
            const buildingClass = 
                'building ' + placeName + 
                ' type_' + (this.props.kingdomTemplate[i].type === 1 ? 'A' : 'B');
            const cardClass = 'card ' + placeName;

            // Build worker cards deck
            let workersList = [];

            for (let j = 0; j < workersCount; j++) {
                if(placeName !== 'infirmary'){
                    workersList.push(<div key={j} className={cardClass}></div>);
                }else{
                    const workerClass = 'card ' + player.infirmary[j];
                    workersList.push(<div key={j} className={workerClass}></div>);
                }
            }

            ar.push( 
            <div key={i} className="slot">
                <div className="workersCount">{ workersCount }</div>
                <div className={buildingClass}></div>
                <div className="workersCards">{workersList}</div>
            </div> 
            );
        }

        return ar;
    }

    toggleCollapse(){
        this.setState({collapsed: !this.state.collapsed}, () => {
            if(this.state.collapsed === false){
                setTimeout( () => {
                    const top = document.querySelector('.kingdom[data-index="'+this.props.index+'"]').getBoundingClientRect().top + window.scrollY - 60;

                    window.scrollTo({
                        top: top,
                        behavior: "smooth"
                    });
                }, 500);
                
            }
        });
    }

    render() {
        const player = this.props.players[this.props.playerIndex];
        const className = 'kingdom ' + (this.state.collapsed === true ? 'collapsed' : '');

        return (
            <div className={className} data-index={this.props.index}>
                <div className="title">Kingdom of <span className="nickname" style={{color: player.color}}>{player.nickname}</span></div>
                <label className="toggleCollapse">
                    <input type="checkbox" value={this.state.collapsed} onChange={this.toggleCollapse}  />
                    <span>{this.state.collapsed === true ? 'Expand' : 'Collapse'}</span>
                </label>                
                <div className="playerInfo">   
                    <div className="row workers">{player.workers} / 5</div>
                    <div className="row points">{player.points}</div>
                </div>
                <div className="buildings">
                    {this.getSlots()}
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        kingdomTemplate: state.players.kingdomTemplate,
        players: state.players.list
    }),
    dispatch => ({})
)(Kingdom);