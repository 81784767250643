import React, { Component } from 'react';
import { connect } from 'react-redux';

class GameHeader extends Component {
    constructor(props) {
        super(props);
        this.handleNicknameClick = this.handleNicknameClick.bind(this);
    }

    handleNicknameClick(e){
        const playerIndex = this.props.game.activePlayerIndex;
        const top = document.querySelector('.kingdom[data-index="'+playerIndex+'"]').getBoundingClientRect().top + window.scrollY - 60;

        e.preventDefault();
        window.scrollTo({
            top: top,
            behavior: "smooth"
        });
    }

    render() {
        const playerIndex = this.props.game.activePlayerIndex;
        const activePlayerName = this.props.players[playerIndex].nickname;

        return (
            <div className="gameHeader">
                <div className="turn">Turn {this.props.game.turn}. </div>
                <div>
                    Active player - <span className="playerName" onClick={this.handleNicknameClick}>{activePlayerName}</span>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        players: state.players.list,
        game: state.players.gameInfo
    }),
    dispatch => ({
        // onFillMarket: playersCount => {
        //     dispatch({ type: 'STANDART_FILL_MARKET', playersCount: playersCount });
        // }
    })
)(GameHeader);
