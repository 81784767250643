import React, { Component } from 'react';
import { connect } from 'react-redux';
import MarketSlot from './MarketSlot';
import ChooseWorkerModal from './ChooseWorkerModal';
import CastleRangeModal from './CastleRangeModal';

class Market extends Component {
    constructor(props) {
        super(props);

        this.state = {
            placeName: '',

            slotIndex: -1,
            needSelectModal: false,
            selectCard_1: '',
            selectCard_2: '',

            needCastleRangeModal: false,
            rangeModalWorkers: -1,
        };

        this.onSlotSelect = this.onSlotSelect.bind(this);
        this.buyCard = this.buyCard.bind(this);
        this.onConfirmCastleRange = this.onConfirmCastleRange.bind(this);
        this.confirmBuy = this.confirmBuy.bind(this);
    }

    onSlotSelect(index) {
        const cardName = this.props.market.slots[index].name;
        const splitedName = cardName.split('-');

        if (splitedName.length > 1) {
            this.setState({
                slotIndex: index,
                needSelectModal: true,
                selectCard_1: splitedName[0],
                selectCard_2: splitedName[1]
            });
        } else {
            this.buyCard(cardName, index);
        }
    }

    buyCard(cardName, slotIndex) {
        const slot = this.props.market.slots[slotIndex];
        const playerIndex = this.props.game.activePlayerIndex;
        let workersMod = 0;

        // Update player workers count
        workersMod -= slotIndex; // slot price in workers

        if (slot.workers && slot.workers > 0)
            workersMod += slot.workers;

        if (workersMod !== 0)
            this.props.onPlayerWorkersUpdate(playerIndex, workersMod);


        // Reset select modal
        this.setState({
            needSelectModal: false,
        });

        // If Castle with type B
        if (
            cardName === 'castle' && 
            this.props.kingdomTemplate[6].type === 2
        ) {
            this.setState({
                placeName: cardName,
                slotIndex: slotIndex,
                needCastleRangeModal: true,
                rangeModalWorkers: this.props.players[playerIndex].workers,
            });
        } else {
            this.setState({
                placeName: cardName,
                slotIndex: slotIndex,
            }, () => {
                this.confirmBuy();
            });
        }
    }

    getSlots() {
        const playerIndex = this.props.game.activePlayerIndex;
        const activePlayer = this.props.players[playerIndex];
        const slots = this.props.market.slots;
        let ar = [];

        if( typeof slots !== 'undefined' && slots.length > 0 ){
            for (let i = 0; i < slots.length; i++) {
                const slot = slots[i];

                if(!slot)
                    continue;

                const workers = slot.workers ? slot.workers : 0;
                let canSelect = false;
    
                if (activePlayer.isBot === false && activePlayer.workers >= i) {
                    canSelect = true;
                }
    
    
                ar.push(
                    <MarketSlot
                        key={i}
                        id={i}
                        workerName={slot.name}
                        workers={workers}
                        canSelect={canSelect}
                        onSlotSelect={this.onSlotSelect}
                    />
                );
            }
        }

        return ar;
    }

    getStoreSlot() {
        const marketStore = this.props.market.store;

        if (marketStore.length > 0) {
            const lastSlot = marketStore[marketStore.length - 1];
            const storeClass = 'card store-slot bg-' + lastSlot.lvl;

            return <div className="slot">
                <div className={storeClass}></div>
            </div>;
        }

        return '';
    }

    getChooseWorkerModal() {
        if (
            this.state.needSelectModal === true &&
            this.state.slotIndex >= 0 &&
            this.state.selectCard_1.length > 0 &&
            this.state.selectCard_2.length > 0
        ) {
            return <ChooseWorkerModal
                slotIndex={this.state.slotIndex}
                card_1={this.state.selectCard_1}
                card_2={this.state.selectCard_2}
                onCardSelect={this.buyCard}
            />
        }
        return;
    }

    getCastleRangeModal() {
        if (
            this.state.needCastleRangeModal === true &&
            this.state.rangeModalWorkers >= 0 && this.state.rangeModalWorkers <= 5
        ) {
            const workers = this.state.rangeModalWorkers;
            const playerIndex = this.props.game.activePlayerIndex;
            const activePlayer = this.props.players[playerIndex];
            let maxRange = workers + activePlayer.points;

            if(maxRange > 5)
                maxRange = 5;

            return <CastleRangeModal
                workers={workers}
                max={maxRange}
                onConfirm={this.onConfirmCastleRange} />;
        }
        return;
    }

    onConfirmCastleRange(workers) {
        this.setState({
            needCastleRangeModal: false,
            rangeModalWorkers: workers,
        }, () => {
            this.confirmBuy();
        });
    }

    confirmBuy() {
        // Update market slots, calc points
        this.props.onBuyWorker({
            slotIndex: this.state.slotIndex,
            playerIndex: this.props.game.activePlayerIndex,
            placeName: this.state.placeName,
            rangeModalWorkers: this.state.rangeModalWorkers
        });

        // Reset state
        this.setState({
            slotIndex: -1,
            needSelectModal: false,
            selectCard_1: '',
            selectCard_2: '',
            placeName: '',
            rangeModalWorkers: -1
        });
    }

    render() {
        return (
            <>
                {this.getCastleRangeModal()}
                {this.getChooseWorkerModal()}
                <div className="market">
                    {this.getSlots()}
                    {this.getStoreSlot()}
                </div>
            </>
        );
    }
}

export default connect(
    state => ({
        market: state.market,
        players: state.players.list,
        game: state.players.gameInfo,
        kingdomTemplate: state.players.kingdomTemplate
    }),
    dispatch => ({
        onPlayerWorkersUpdate: (playerIndex, mod) => {
            dispatch({
                type: 'UPDATE_PLAYER_WORKERS',
                playerIndex: playerIndex,
                mod: mod
            });
        },
        onBuyWorker: opts => {
            opts.type = 'WORKER_CHOSEN';
            dispatch(opts);
        }
    })
)(Market);