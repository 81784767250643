import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import market from './market';
import players from './players';


export default combineReducers({
    routing: routerReducer,
    market,
    players,
});