import React, { Component } from 'react';
import Card from './Card';

export default class MarketSlot extends Component {
    handleSelectClick() {
        this.props.onSlotSelect(this.props.id);
    }

    selectBtn() {
        if (this.props.canSelect && this.props.canSelect === true) {
            return <div className="btn" onClick={this.handleSelectClick.bind(this)}>Select</div>;
        }
    } 

    render() {
        let cardClass = '';

        if (this.props.workers > 0) {
            cardClass += 'haveWorkers';
        }

        return (
            <div className="slot">
                <Card
                    name={this.props.workerName}
                    workers={this.props.workers}
                    extraClass={cardClass} />
                {this.selectBtn()}
            </div>
        );
    }
}