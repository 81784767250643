import React, { Component } from 'react';

class CastleRangeModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: -1,
            points: 0
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
    }

    handleChange(event) {
        const initWorkers = this.props.workers;
        const val = event.target.value;
        const diff = Math.abs(initWorkers - val);
        let points = this.state.points;

        if(val > initWorkers){
            points = -diff;
        }else if(val < initWorkers){
            points = diff;
        }else{
            points = 0;
        }

        this.setState({
            value: val,
            points: points
        });
    }

    handleConfirm(e){
        e.stopPropagation();
        this.props.onConfirm(this.state.value);
    }

    render() {
        const inputWorkers = this.state.value === -1 ? this.props.workers : this.state.value;

        return (
            <div className="modal castleRangeAction">
                <div className="modal-content">
                    <div className="title">Sell ​​or buy workers by changing slider</div>
                    <div className="sign">
                        <div className="workers">{inputWorkers}</div>
                        <div className="points">{this.state.points > 0 ? '+' : ''}{this.state.points}</div>
                    </div>                    
                    <input 
                        type="range" 
                        step="1" min="0" max={this.props.max} 
                        value={inputWorkers}
                        onChange={this.handleChange}></input>   
                    <div className="btn confirm" onClick={this.handleConfirm} >Confirm</div>                             
                </div>
            </div>
        );
    }
}

export default CastleRangeModal;