import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startGame: false
    };

    this.startGame = this.startGame.bind(this);
  }

  startGame() {
    this.setState({ startGame: true });
  }

  render() {
    if (this.state.startGame === true)
      return <Redirect to="/settings" />;

    const rulesUrl = process.env.PUBLIC_URL + '/rules/eng_rules.pdf';

    return (
      <div className="home">
        <section className="home__about wrap">
          <h1>Majesty</h1>
          <p>Hello everyone! </p>
          <p>I am glad to present this simple but fun board game adaptation. If you never heard before about this game, dont worry you can spend 5 minutes and read board game rules <a href={rulesUrl} target="_blank" rel="noopener noreferrer">here</a> (pdf file with rules on link)</p>
          <p>Have fun and dont forget to support board game author by buying board game if you can. </p>
          <div className="playBtn" onClick={this.startGame}>Play</div>
        </section>
      </div>
    );
  }
}

export default connect(
  state => ({}),
  dispatch => ({})
)(Home);
