import Utils from '../helpers/Utils';

/**
 * lvl_1 = cards count on lvl 1
 * lvl_2 = cards count on lvl 2
 */
const cardsDB = [
    {worker: "mill", lvl_1: 7, lvl_2: 2},
    {worker: "brewery", lvl_1: 5, lvl_2: 2},
    {worker: "cottage", lvl_1: 3, lvl_2: 2},
    {worker: "guardhouse", lvl_1: 3, lvl_2: 2},
    {worker: "barracks", lvl_1: 2, lvl_2: 1},
    {worker: "inn", lvl_1: 2, lvl_2: 2},
    {worker: "castle", lvl_1: 3, lvl_2: 2},
    {worker: "mill-barracks", lvl_1: 1, lvl_2: 1},
    {worker: "mill-brewery", lvl_1: 1, lvl_2: 1},
    {worker: "cottage-brewery", lvl_1: 1, lvl_2: 2},
    {worker: "cottage-guardhouse", lvl_1: 1, lvl_2: 2},
    {worker: "cottage-castle", lvl_2: 1},
    {worker: "barracks-guardhouse", lvl_1: 1, lvl_2: 2},
    {worker: "guardhouse-castle", lvl_1: 1},
    {worker: "inn-barracks", lvl_1: 1, lvl_2: 2},
    {worker: "inn-castle", lvl_1: 1, lvl_2: 1},
    {worker: "inn-cottage", lvl_2: 1},
    {worker: "brewery-barracks", lvl_2: 1},
];
const initialState = {
    store: [],
    slots: [],
};

export default function gameStore(state = initialState, action){
    if(action.type === 'STANDART_FILL_MARKET' || action.type === 'RANDOM_FILL_MARKET'){
        let isStandartFill = action.type === 'STANDART_FILL_MARKET' ? true : false;
        let playersCount = action.playersCount ? action.playersCount : 2;
        let list_lvl_1 = [];
        let list_lvl_2 = [];

        // Decompose databse
        cardsDB.forEach( el => {
            if(el.lvl_1 != null){
                for (let i = 0; i < el.lvl_1; i++) {
                    list_lvl_1.push({name: el.worker, lvl: 1});
                }
            }
            if(el.lvl_2 != null){
                for (let i = 0; i < el.lvl_2; i++) {
                    list_lvl_2.push({name: el.worker, lvl: 2});
                }
            }
        });

        // Shuffle arrays
        Utils.shuffle(list_lvl_1);
        Utils.shuffle(list_lvl_2);

        if(isStandartFill === true){
            // Setup lvl 1 - custom cards count based on players count
            let saveCount = 6; // workers from first lvl

            if(playersCount === 3)
                saveCount = 14;
            if(playersCount === 4)
                saveCount = 26;

            list_lvl_1 = list_lvl_1.slice(0, saveCount);
        }

        // Create final list and slots
        let finalStore = list_lvl_2.concat(list_lvl_1);
        let slots;

        if(isStandartFill === false){
            // If its Random Fill - shuffle final array
            Utils.shuffle(finalStore);
        }
        
        slots = finalStore.slice(finalStore.length - 6, finalStore.length);
        finalStore.splice(finalStore.length - 6, 6);

        return {
            store: finalStore,
            slots: slots
        };
    }
    else if(
        action.type === 'WORKER_CHOSEN' && 
        typeof action.slotIndex !== 'undefined' && action.slotIndex >= 0 && action.slotIndex < 6 
    ){     
        let store = state.store;

        // Update workers count and create tmp Slots list
        let slots = state.slots.map((slot, ind) => {
            if(ind < action.slotIndex){
                if(!slot.workers)
                    slot.workers = 0;
                slot.workers += 1;
            }
            return slot;
        });

        // Remove selected slot
        slots.splice(action.slotIndex, 1);

        // Add new slot
        slots.push( store.pop() );

        return {
            store: store,
            slots: slots
        };
    }

    return state;
}